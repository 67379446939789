<section class="slider">
    <div>
        <div id="slid1">
            <div>
                <img class="leftBaner" src="../../assets/images/HBank/SVG/Untitled.svg" />
            </div>
            <div class="row Par">
                <div class="col-lg-4 col-12 mb-3">
                    <div class="block block-slid1">
                        <p class="header mb-2">من نحن</p>
                    </div>
                </div>
                <div class="col-lg-6 col-12 block-pag">
                    ما نسعى إليه في بنك حضرموت التجاري أن نقدم لكم حلولاً مصرفية لكافة الأفراد والمؤسسات في جميع أنحاء
                    اليمن الحبيب، لنحقق الأثر الإيجابي على الاقتصاد الوطني وعلى المواطن.

                    <br>
                    <br>

                    ونوفر لكم باقة منوعة من
                    المنتجات والتي صممت بعناية لتلبية كافة الاحتياجات المالية، للأفراد والمشاريع الناشئة والصغيرة
                    والمتوسطة ووصولاً إلى الشركات والمشاريع الكبرى.
                    <br>
                    <br>
                    ويطمح كادرنا المختص بخبراتهم المصرفية المحلية والدولية لتطوير عملنا المصرفي وتقديم أفضل خدمة عملاء،
                    لمواكبة كل ما هو جديد.

                </div>
            </div>

        </div>
    </div>
</section>