<!-- Slider Start -->
<section class="slider custom-slid">
	<div>
		<div id="slid1" *ngIf="selectedSlid == 3">
			<div>
				<img class="leftBaner" src="../../assets/images/HBank/SVG/Untitled.svg" />
			</div>
			<div class="custom-parent-image">
				<div class="">
					<div class="block">

						<img src="../../assets/images/HBank/SVG/manLogo.svg" class="custom-img slider1-image"
							width="100%" />
						<!-- <span class="d-block mb-3 text-white text-capitalize">Prepare for new future</span>
				<h1 class="animated fadeInUp mb-5">Our work is <br>presentation of our <br>capabilities.</h1>
				<a href="#"  class="btn btn-main animated fadeInUp btn-round-full" aria-label="Get started">Get started<i class="btn-icon fa fa-angle-right ml-2"></i></a> -->
					</div>
				</div>
			</div>
			<div>
				<img class="smallLogo" src="../../assets/images/HBank/SVG/logoSmall.svg" />
			</div>
			<!-- <div (click)="selectedSlid = 1">
				<img class="smallDot" src="../../assets/images/HBank/SVG/test.svg" />
			</div> -->
			<div (click)="selectedSlid = 2">
				<img class="smallDot-space" src="../../assets/images/HBank/SVG/test.svg" />
			</div>
			<div (click)="selectedSlid = 3">
				<img class="smallDot-space space-active svgtest" src="../../assets/images/HBank/SVG/test.svg" />
				<img class="smallDot-space-more" src="../../assets/images/HBank/SVG/blockSq.svg" />
			</div>
		</div>
		<div id="slid2" *ngIf="selectedSlid == 2">

			<div>
				<img class="leftBaner" src="../../assets/images/HBank/SVG/Untitled.svg" />
			</div>
			<div class="row m-0">
				<div class="">
					<div class="">
						<p class="header">حلول مصرفية</p>
						<p class="spa">
							وفق الأصول والمبادئ
						</p>
					</div>
				</div>
			</div>
			<div>
				<!-- <div (click)="selectedSlid = 1">
					<img class="smallDot" src="../../assets/images/HBank/SVG/test.svg" />
				</div> -->
				<div (click)="selectedSlid = 2">
					<img class="smallDot-space space-active-2" src="../../assets/images/HBank/SVG/test.svg" />
					<img class="smallDot-space" src="../../assets/images/HBank/SVG/blockSq.svg" />
				</div>
				<div (click)="selectedSlid = 3">
					<img class="smallDot-space-more" src="../../assets/images/HBank/SVG/test.svg" />
				</div>
			</div>
		</div>
		<!-- <div id="slid3"  *ngIf="selectedSlid == 1">
			<div>
				<img class="leftBaner" src="../../assets/images/HBank/SVG/Untitled.svg" />
			</div>
			<div class="row">
				<div class="col-lg-9 col-md-10">
					<div class="block">

						<img src="../../assets/images/HBank/SVG/manLogo.svg" />
						</div>
				</div>
			</div>
			<div>
				<img class="smallLogo" src="../../assets/images/HBank/SVG/logoSmall.svg" />
			</div>
			<div>
				<div (click)="selectedSlid = 1">
					<img class="smallDot space-active-2" src="../../assets/images/HBank/SVG/test.svg" />
					<img class="smallDot" src="../../assets/images/HBank/SVG/blockSq.svg" />
				</div>
				<div (click)="selectedSlid = 2">
					<img class="smallDot-space" src="../../assets/images/HBank/SVG/test.svg" />
				</div>
				<div  (click)="selectedSlid = 3">
					<img class="smallDot-space-more" src="../../assets/images/HBank/SVG/test.svg" />
				</div>
			</div>

		</div> -->
	</div>
</section>
<!-- Section Intro Start -->