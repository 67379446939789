import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trans',
  templateUrl: './trans.component.html',
  styleUrls: ['./trans.component.css']
})
export class TransComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
