<section class="slider">
    <div>
        <div id="slid1">
            <div>
                <img class="leftBaner" src="../../assets/images/HBank/SVG/Untitled.svg" />
            </div>
            <div class="row">
                <div class="col-lg-6 col-12 mb-3">
                    <p class="header">
                        أهداف البنك
                    </p>
                </div>
            </div>
            <div class="row Par">
                <div class="col-4">
                    <div class="block block-slid1 d-block-lg">
                        <!-- <img src="../../assets/images/HBank/SVG/manLogo.svg" /> -->
                    </div>
                </div>
                <div class="col-lg-8 col-12 Par">
                    أن نبني مستقبلاً من ماضٍ نَعتز به، ومن قِيمٍ وَرِثْنَاها نحو قِمِمٍ وِجْهَتُنَا، ونماءٍ مستمر وقيمٍ
                    أخلاقية ومعايير ثابته.
                </div>
            </div>

        </div>
    </div>
</section>