<!-- Slider Start -->
<section class="slider">
    <div>
        <div id="slid1">
            <!-- <div>
				<img class="leftBaner" src="../../assets/images/HBank/SVG/Untitled.svg" />
			</div> -->
            <div class="row m-0">
                <div class="col-lg-5 col-12">
                    <div class="block block-slid1 header">
                        بنك حضرموت التجاري يتعاقد مع شركة ICSfs لشراء تراخيص نظام BANKS
                    </div>
                </div>
                <div class="col-lg-6 col-12 Par">
                    <div class="block block-slid1 par-slider" [routerLink]="['/news-sub', 1]">
                        بفضل الله أتمت إدارة بنك حضر موت التجاري التعاقد مع شركة ICSfs على شراء تراخيص نظام BANKS ليكون
                        نظام التشغيل الأساسي للعمليات البنكية.
                    </div>
                    <br>
                    <div class="action-slide">
                        <div class="more" [routerLink]="['/news-sub', 1]">
                            اقرأ المزيد
                            <i class="fa fa-play" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div>
            <img class="leftBaner" src="../../assets/images/HBank/SVG/Untitled.svg" />
        </div>

        <div id="slid2">
            <div class="row m-0">
                <div class="col-lg-5 col-12">
                    <div class="block block-slid1 header">
                        بنك حضرموت التجاري يتعاقد مع شركة أوراكل العالمية لإدارة قواعد البيانات
                    </div>
                </div>
                <div class="col-lg-6 col-12 Par">
                    <div class="block block-slid1 par-slider">
                        بفضل الله أتمت إدارة بنك حضر موت التجاري التعاقد مع شركة أوراكل العالمية Oracel لتفيل الترخيص
                        اللازمة لإدارة قواعد البيانات؛
                    </div>
                    <br>
                    <div class="action-slide">
                        <div class="more" [routerLink]="['/news-sub', 2]">
                            اقرأ المزيد
                            <i class="fa fa-play" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div>
            <img class="leftBaner" src="../../assets/images/HBank/SVG/Untitled.svg" />
        </div>

        <div id="slid3">
            <div class="row m-0">
                <div class="col-lg-5 col-12">
                    <div class="block block-slid1 header">
                        المحافظ يفتتح بنك حضرموت التجاري
                    </div>
                </div>
                <div class="col-lg-6 col-12 Par">
                    <div class="block block-slid1 par-slider">
                        بفضل من الله وتوفيقة تم افتتاح بنك حضرموت التجاري بتاريخ ٢٣/١/٢٠٢٣م تحت رعاية سعادة الأستاذ /
                        مبخوت مبارك بن ماضي محافظ محافظة حضرموت.
                    </div>
                    <br>
                    <div class="action-slide">
                        <div class="more" [routerLink]="['/news-sub', 3]">
                            اقرأ المزيد
                            <i class="fa fa-play" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    </div>
</section>
<!-- Section Intro Start -->