import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sub',
  templateUrl: './sub.component.html',
  styleUrls: ['./sub.component.css'],
})
export class SubComponent implements OnInit {
  id:any = this.activeRout.snapshot.paramMap.get('id');
  
  news = [
    {
      title:'بنك حضرموت التجاري يتعاقد مع شركة ICSfs لشراء تراخيص نظام BANKS',
      body:'بفضل الله أتمت إدارة بنك حضرموت التجاري التعاقد مع شركة ICSFS على شراء تراخيص نظام BANKS ليكون نظام التشغيل الأساسي للعمليات البنكية. يعد نظام BANKS من أفضل الأنظمة ملائمة للسوق اليمنية من حيث سهولة الربط الالكتروني، وما يوفره النظام من حلول مبتكرة مبنية على تكامل آمن وسريع. وقد تم توقيع العقود بالمكتب الإقليمي لشركة ICSFS بعمان – الأردن، بحضور السيد / خالد جبر المدير العام لبنك حضرموت التجاري، والسيد/ روبرت حزبون المدير التنفيذي لشركة ICSfs ويأتي هذا التعاقد في إطار رؤية البنك وتطلعاته ليكون أحد البنوك الرائدة في مجال تقديم خدمات مصرفية مميزة للمؤسسات والافراد. إيمانا من إدارة البنك بالقواعد التي يعمل عليها البنك المركزي اليمني والتي تعزز النهوض بالاقتصاد من خلال تقديم خدمات مالية متطورة باستخدام التقنيات التكنولوجية الحديثة.',
      image:'../../../../../assets/images/news/IMG_6198.JPG',
    },
    {
      title:'بنك حضرموت التجاري يتعاقد مع شركة أوراكل العالمية لإدارة قواعد البيانات',
      body:'بفضل الله أتمت إدارة بنك حضرموت التجاري التعاقد مع شركة أوراكل العالمية Oracle لتفعيل التراخيص اللازمة لإدارة قواعد البيانات؛ وذلك لضرورة معالجة وحماية قواعد بيانات عملاء البنك بطريقة آمنة وفعالة، واستكمالاً لخطة الاستثمار لبناء أنظمة تقنية المعلومات، وفقاً للمتطلبات الإقليمية والدولية، وتأسيس قواعد بيانات آمنه لتشغيل النظام البنكي Banks الذي قد تعاقدت عليه إدارة البنك سابقاً. وقد تم توقيع العقود بالمكتب الإقليمي لشركة أوراكل بدبي – الإمارات العربية المتحدة، بحضور السيد / خالد جبر المدير العام لبنك حضرموت التجاري، والسيد/ راشد المصاروه، مدير مبيعات تكنولوجيا اوراكل.',
      image:'../../../../../assets/images/news/oracle.jpeg',
    },
    {
      title:'افتتاح بنك حضرموت التجاري تحت رعاية سعادة الأستاذ / مبخوت مبارك بن ماضي محافظ محافظة حضرموت',
      body:'  شرفنا سعادة الاستاذ / مبخوت مبارك بن ماضي بإفتتاح البنك وبحضور كلا من:' +
     ' وفد من البنك المركزي يترأسه'+
     ' • نائب محافظ البنك المركزي اليمني الأستاذ/ الدكتور محمد عمر باناجه ممثلاً عن البنك المركزي.'+
     ' • وكيل اول وزارة الصناعة والتجارة الأستاذ/ علي عاطف الشرفي ممثلا عن وزارة الصناعة والتجارة.'+
     ' • الأستاذ/ صالح عبود العمقي الأمين العام للمجلس المحلي بمحافظة حضرموت.'+
     '  وعدد من المسؤولين المدنيين والعسكريين ومجموعة من رجال المال والاعمال راجين العلي القدير ان يوفقنا لخدمة عملاؤنا على أحسن صورة مع استعدادنا لتقديم أفضل الحلول المصرفية. '+
     ' كما توجه الشيخ / سعيد بن قربان رئيس مجلس إدارة البنك بكلمة للحضور عن إستراتيجيات وتوجهات البنك وخططه المستقبلية فقال في ايجاز: '+

      'لقد شكلت خبراتنا البنكية المتراكمة وتجاربنا كرجال مال واعمال،مداميك البنك، وشعاره النابض بهويته، اصالة مستمدة من إرث حضرموت الإنساني والاقتصادي، والتزام الاحتفاظ بالقمة في التميز، والتفرد في المعاملات البنكية، والثبات على القيم التجارية التي جعلت من اسم حضرموت مرادفا للأمانة في خدمة الصالح العام، والإخلاص للنهوض بالاقتصاد الوطني ودفع عجلة الاستثمار واستقطاب رؤوس الأموال وخلق فرص عمل للشباب، وتمويل مشروعاتهم الصغيرة والمتوسطة.',
      image:'../../../../../assets/images/news/362A6474 (1).jpg',
    },
    {
      title:'بنك حضرموت التجاري يتعاقد مع شركة FINASTRA البريطانية',
      body:'بفضل الله أتمت إدارة بنك حضرموت التجاري التعاقد مع شركة FINASTRA البريطانية؛ وذلك لإدارة خدمات السوفت SWIFT. وقد جاء هذا التعاقد وفقاً للخطة الاستراتيجية للبنك لتقديم خدمات مميزة لعملائه، وحرصاً من مجلس الإدارة أن تكون الخدمات المقدمة بالتعاون مع شركاء ذوي خبرة عالمية بمجالات الأعمال البنكية. وقد تم توقيع العقود بالمكتب الإقليمي لشركة أوراكل بدبي – الإمارات العربية المتحدة، بحضور السيد / خالد جبر المدير العام لبنك حضرموت التجاري. والجدير ذكره أن شركة FINASTRA هي أحد مقدمي خدمات السوفت المعتمدين، ولديها خبرات مميزة بالوطن العربي مع العديد من البنوك الإقليمية لتقديم الخدمات البنكية المساعدة.',
      image:'../../../../../assets/images/news/finastar.jpg',
    }
  ];
  constructor(private activeRout: ActivatedRoute,) {}

  ngOnInit(): void {
  }
}
