<section class="slider">
    <div>
        <div id="slid1">
            <div>
                <img class="leftBaner" src="../../assets/images/HBank/SVG/Untitled.svg" />
            </div>
            <div class="row">
                <div class="col-12 mb-3" (click)="selectes = 0">
                    <p class="header">
                        الحسابات الجارية
                    </p>
                </div>
            </div>
            <div class="row ">
                <div class="col-md-4 col-12">
                    <div class="block block-slid1">
                        <ul class="header-ul">
                            <li (click)="selectes = 1" [ngClass]="{'selected' : 1 == selectes}">
                                <span class="title-select">
                                    الجمعيات
                                </span>

                            </li>
                            <li (click)="selectes = 2" [ngClass]="{'selected' : 2 == selectes}">

                                <span class="title-select">
                                    الشركات
                                </span>
                            </li>
                            <li (click)="selectes = 3" [ngClass]="{'selected' : 3 == selectes}">
                                <span class="title-select">
                                    الأفراد
                                </span>

                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-7 col-12 Par" [ngClass]="{'selected-li' : 0 == selectes}">
                    <span class="desc-account">
                        نقدم لكم خدمة فتح الحسابات الجارية بأنواعها لجميع العملاء وبميزات تجعل من الحساب الجاري بوابةً
                        لتلبية جميع احتياجاتهم ومتطلباتهم من خلال الخدمات والمنتجات المتنوعة وبأسهل الوسائل العصرية.
                    </span>

                </div>
                <div class="col-md-7 col-12 Par" [ngClass]="{'selected-li' : 1 == selectes}">
                    <ul class="ul-account">
                        <li>رسالة رسمية من مكتب الشؤون الاجتماعية والعمل موضحة المخولين بإدارة الحساب.</li>
                        <li>رسالة رسمية من الجمعية بطلب فتح الحساب.</li>
                        <li>ترخيص المؤسسة ساري المفعول.</li>
                        <li>وثائق إثبات الهوية للمؤسسين / المخولين بالتوقيع.</li>
                        <li>النظام الأساسي.</li>
                    </ul>
                </div>
                <div class="col-md-7 col-12 Par" [ngClass]="{'selected-li' : 2 == selectes}">
                    <ul class="ul-account">
                        <li>رسالة رسمية من الشركة بطلب فتح الحساب.</li>
                        <li>السجل التجاري ساري المفعول.</li>
                        <li>رخصة مزاولة المهنة سارية المفعول.</li>
                        <li>النظام الأساسي/عقد التأسيس.</li>
                        <li>البطاقة الضريبية.</li>
                        <li>وثيقة إثبات الهوية للأشخاص الذين يملكون 10% فاكثر من ملكية الشركة.</li>
                        <li>وثيقة إثبات الهوية للمخولين بالتوقيع.</li>
                    </ul>
                </div>
                <div class="col-md-7 col-12 Par" [ngClass]="{'selected-li' : 3 == selectes}">
                    <ul class="ul-account">
                        <li>وثيقة إثبات هوية سارية المفعول (بطاقة شخصية – جوز سفر – بطاقة عائلية) شهادة ميلاد للقصر.
                        </li>
                        <li>صورة شخصية 4*6.</li>
                        <li>مستند إثبات السكن لا تقل صلاحيته عن 3 أشهر (فاتورة ماء – فاتورة كهرباء -عقد إجار ).</li>
                        <li>إقامة سارية المفعول للأجانب.</li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</section>