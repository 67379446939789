<!-- Header Start -->
<header class="navigation">
    <div class="header-top d-flex align-items-center col-12">

        <div class="header-top-conta">
            <div class="row m-0 header-top-conta justify-content-between align-items-center">
                <div class="col-lg-2 col-md-4 col-6 p-0">
                    <div class="header-top-socials text-center text-lg-left text-md-left">
                        <img class="logo" src="../../assets/images/HBank/SVG/Asset 3.svg" />

                        <!-- <a href="https://www.facebook.com/themefisher" aria-label="facebook"><i
                                class="fab fa-facebook-f"></i></a>
                        <a href="https://twitter.com/themefisher" aria-label="twitter"><i
                                class="fab fa-twitter"></i></a>
                        <a href="https://github.com/themefisher/" aria-label="github"><i class="fab fa-github"></i></a> -->
                    </div>
                </div>

            </div>
        </div>
        <button #navbarToggler class="navbar-toggler d-hidden-lg" type="button" data-toggle="collapse"
            data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>

        </button>
    </div>

    <div id="navbar" class="">
        <div class="">
            <div class="row header-top-conta m-0">
                <div class="col-lg-12">
                    <nav class="navbar navbar-expand-lg header-navbar">


                        <div class="collapse navbar-collapse text-center collapse" id="navbarsExample09">
                            <ul class="navbar-nav ml-auto p-0">
                                <li class="nav-item underline" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact:true}">
                                    <a class="navbar-link d-block" (click)="navigate('')">
                                        <span>الصفحة الرئيسية</span>
                                    </a>
                                </li>
                                <li class="nav-item underline dropdown" routerLinkActive="active">
                                    <a class="navbar-link cursor-pointer d-block" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <span>نبذة عن البنك</span>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <div class="dropdown-item" type="button" (click)="navigate('who')"
                                            routerLinkActive="active-subtitle">
                                            من نحن
                                        </div>
                                        <div class="dropdown-item" type="button" (click)="navigate('ourRules')"
                                            routerLinkActive="active-subtitle">مبادئ البنك وقيمه</div>
                                        <div class="dropdown-item" type="button" (click)="navigate('seen')"
                                            routerLinkActive="active-subtitle">رؤية البنك</div>
                                        <div class="dropdown-item" type="button" (click)="navigate('gools')"
                                            routerLinkActive="active-subtitle">أهداف البنك</div>
                                        <div class="dropdown-item" type="button" (click)="navigate('news')"
                                            routerLinkActive="active-subtitle">أخبار البنك</div>
                                    </div>
                                </li>
                                <li class="nav-item underline dropdown" routerLinkActive="active">
                                    <a class="navbar-link cursor-pointer d-block" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <span>خدمات مصرفية</span>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <div class="dropdown-item" type="button" (click)="navigate('current-account')"
                                            routerLinkActive="active-subtitle">
                                            الحسابات الجارية
                                        </div>
                                        <div class="dropdown-item" type="button" (click)="navigate('fx')"
                                            routerLinkActive="active-subtitle">بيع وشراء العملات
                                        </div>
                                        <div class="dropdown-item" type="button" (click)="navigate('trans')"
                                            routerLinkActive="active-subtitle">الحوالات
                                            الداخلية</div>
                                    </div>
                                </li>
                                <li class="nav-item underline" routerLinkActive="active">
                                    <a class="navbar-link d-block" (click)="navigate('protection')">
                                        <span>الخصوصية والحماية</span>
                                    </a>
                                </li>
                                <li class="nav-item underline">
                                    <a class="navbar-link d-block" href="https://mobile.hadhramoutbank.com/IBS/"
                                        routerLinkActive="active">
                                        <span>حضرموت اونلاين</span>
                                    </a>
                                </li>
                                <li class="nav-item underline">
                                    <a class="navbar-link d-block" (click)="navigate('contact-us')"
                                        routerLinkActive="active">
                                        <span>اتصل بنا</span>
                                    </a>
                                </li>
                            </ul>

                            <div class="text-center content-inputSearch">
                                <div class="input-group">
                                    <input type="search" placeholder=" البحث" aria-describedby="button-addon2"
                                        class="form-control border-0 bg-light">
                                    <div class="input-group-prepend">
                                        <button id="button-addon2" type="submit"
                                            class="btn btn-link icon-search-navbar text-warning d-flex align-items-center"><i
                                                class="fa fa-search"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- Header Close -->