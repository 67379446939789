<section class="slider">
    <div>
        <div id="slid1">
            <div>
                <img class="leftBaner" src="../../assets/images/HBank/SVG/Untitled.svg" />
            </div>
            <div class="row">
                <div class="col-xl-8 col-sm-10 col-12 col-lg-9">
                    <p class="header">
                        {{news[id-1].title}}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-12 col-lg-6">
                    <div class="block block-slid1">
                        <img class="slid1-image" [src]="news[id-1].image" />
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-12 Par">
                    {{news[id-1].body}}
                </div>
            </div>

        </div>
    </div>
</section>