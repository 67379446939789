<!-- <div class="container"> -->

<div class="main">
    <div class="content-app">
        <app-header></app-header>
        <router-outlet></router-outlet>
        <!-- </div> -->


    </div>
    <div class="row sossial">
        <div class="col-md-6 col-7 content-icons-footer">
            <a href="https://www.facebook.com/HadhramoutCBank">
                <i class="fab fa-facebook-f icon"></i>
            </a>

            <a href="https://www.instagram.com/hadhramoutcbank">
                <i class="fab fa-instagram icon"></i></a>

            <a href="https://www.linkedin.com/company/hadhramoutbank">
                <i class="fab fa-linkedin-in icon"></i></a>

            <a href=" https://www.youtube.com/@hadhramoutcbank">
                <i class="fab fa-youtube icon"></i></a>

            <a href="https://twitter.com/HadhramoutCBank">
                <i class="fab fa-twitter icon"></i>
            </a>
        </div>
        <div class="col-md-6 col-5">
            <p class="icon terms" (click)="open(content)">
                الشروط و الأحكام
            </p>
        </div>
    </div>
    <ng-template #content let-modal>
        <div class="modal-header">
            <!-- <h4 class="modal-title" id="modal-basic-title">Profile update</h4> -->
            <button type="button" class="btn-close custom-button-close" aria-label="Close"
                (click)="modal.dismiss('Cross click')">X</button>
        </div>
        <div class="modal-body custom-modal-body p-0">
            <div class="temsDiv">
                <div class="termsDivBody">
                    <section class="slider terms">
                        <div>
                            <div class="dialog-slide1">
                                <div>
                                    <img class="leftBaner" src="../../assets/images/HBank/SVG/Untitled.svg" />
                                </div>
                                <div class="row m-0">
                                    <div class="col-12">
                                        <p class="header">
                                            الشروط و الأحكام
                                        </p>
                                    </div>
                                </div>
                                <div class="row m-0">
                                    <div class="col-12">
                                        <p class="Par custom-modile-par">
                                            تنويه: يرجى قراءة هذه الشروط والأحكام بدقة.
                                        </p>
                                    </div>
                                </div>
                                <br>
                                <br>
                                <div class="row m-0 Par custom-Par">
                                    <div class="col-sm-4 col-12">
                                        <div class="block block-slid1">

                                        </div>
                                    </div>
                                    <div class="col-sm-8 Par  col-12 custom-modile-par">
                                        بدخولك هذا الموقع وأي صفحات فيه، فأنت توافق على الالتزام بالشروط والأحكام أدناه.
                                        فإذا لم
                                        تكن
                                        موافقاً على الأحكام والشروط أدناه، فلا تدخل هذا الموقع أو أي صفحات فيه.
                                        جميع الحقوق محفوظة
                                        <br>
                                        حقوق الطبع في الصفحات والشاشات التي تعرض الصفحات وفي المعلومات والمواد الواردة
                                        فيها وفي
                                        ترتيبها، مملوكة لبنك حضرموت التجاري ما لم تتم الإشارة إلى غير ذلك.

                                    </div>
                                </div>
                                <br>
                                <br>
                                <div class="row m-0 Par custom-Par">
                                    <div class="col-sm-4 col-12">
                                        <div class="block block-slid1 title-mobile-par">
                                            العلامات التجارية
                                        </div>
                                    </div>
                                    <div class="col-sm-8 col-12 Par custom-modile-par">
                                        بنك حضرموت التجاري وشعاره هي علامات تجارية وعلامات خدمية مسجلة. كما يجوز للبنك
                                        أيضاً
                                        حقوق
                                        العلامات التجارية والعلامات الخدمية في العلامات الأخرى الواردة في الصفحات.
                                    </div>
                                </div>
                                <br>
                                <br>
                                <div class="row m-0 Par custom-Par">
                                    <div class="col-sm-4 col-12">
                                        <div class="block block-slid1 title-mobile-par">استخدام المعلومات والمواد</div>
                                    </div>
                                    <div class="col-sm-8 col-12 Par custom-modile-par">
                                        المعلومات والمواد الواردة في هذه الصفحات، والشروط والأحكام والأوصاف التي تظهر
                                        تخضع
                                        للتغيير.
                                        لا تتوفر كافة المنتجات والخدمات في كافة المناطق الجغرافية. يخضع تأهلك للمنتجات
                                        وخدمات
                                        بعينها
                                        لما يقرره ويقبله بنك حضرموت التجاري في النهاية. المنتجات الاستثمارية ليست
                                        إلزاماً على
                                        بنك
                                        حضرموت التجاري وليست مضمونة من قبله.
                                    </div>
                                </div>
                                <br>
                                <br>
                                <div class="row m-0 Par custom-Par">
                                    <div class="col-sm-4 col-12">
                                        <div class="block block-slid1 title-mobile-par">
                                            عدم الضمان
                                        </div>
                                    </div>
                                    <div class="col-sm-8 col-12 Par custom-modile-par">
                                        المعلومات والمواد الواردة في هذا الموقع بما في ذلك النصوص والأشكال أو الروابط أو
                                        البنود
                                        الأخرى مقدمة "كما هي عليه" و "حسب توفرها" ولا يضمن بنك حضرموت التجاري دقة أو
                                        كفاية أو
                                        اكتمال
                                        هذه المعلومات والمواد ويتنازل صراحة عن المسؤولية عن الأخطاء أو السهو في
                                        المعلومات
                                        والمواد.
                                        ولا يتم إعطاء أي ضمان من أي نوع، لا ضمني ولا صريح، ولا قانوني بما في ذلك وليس
                                        حصراً على
                                        ضمانات عدم التعدي على حقوق الآخرين، الملكية، القابلية للمتاجرة، الصلاحية لغرض
                                        محدد
                                        والخلو من
                                        فيروسات الكمبيوتر، بمعية هذه المعلومات والمواد.
                                    </div>
                                </div>
                                <br>
                                <br>
                                <div class="row m-0 Par custom-Par">
                                    <div class="col-sm-4 col-12">
                                        <div class="block block-slid1">

                                        </div>
                                    </div>
                                    <div class="col-sm-8 col-12 Par"></div>
                                </div>
                                <br>
                                <br>
                                <div class="row m-0 Par custom-Par">
                                    <div class="col-sm-4 col-12">
                                        <div class="block block-slid1 title-mobile-par">
                                            محدودية المسؤولية
                                        </div>
                                    </div>
                                    <div class="col-sm-8 col-12 Par custom-modile-par">
                                        لا يكون بنك حضرموت التجاري في أي حال مسؤولاً عن أي أَضرار بما في ذلك وليس حصراً
                                        على
                                        الأضرار
                                        المباشرة أو غير المباشرة أو العرضية أو المترتبة، أو الخسائر أو النفقات الناشئة
                                        فيما يتصل
                                        بهذا الموقع أو استخدامه أو عدم المقدرة على استخدامه من قبل أي شخص أو فيما يتصل
                                        بأي فشل
                                        في
                                        الأداء، أو خطأ أو سهو أو انقطاع أو خلل أو تأخير في التشغيل أو الإرسال أو فيروس
                                        كمبيوتر
                                        أو
                                        فشل خط أو نظام، حتى لو كان بنك حضرموت التجاري أو ممثليه قد تم إخطارهم باحتمال
                                        حدوث مثل
                                        هذه
                                        الأضرار أو الخسائر أو النفقات. وتتحمل أنت شخصياً مخاطر الروابط الإلكترونية
                                        لمصادر أخرى
                                        على
                                        الإنترنت، حيث لم يقم بنك حضرموت التجاري بالتحقق أو التأكد من أو مراقبة أو اعتماد
                                        المحتويات
                                        والدقة والآراء التي يتم التعبير عنها والروابط الإلكترونية الأخرى التي تقدمها تلك
                                        المصادر.
                                    </div>
                                </div>
                                <br>
                                <br>
                                <div class="row m-0 Par custom-Par">
                                    <div class="col-sm-4 col-12">
                                        <div class="block block-slid1 title-mobile-par">
                                            ما يتم تقديمه
                                        </div>
                                    </div>
                                    <div class="col-sm-8 col-12 Par custom-modile-par">
                                        تعتبر كافة المعلومات المقدمة لبنك حضرموت التجاري عن طريق هذا الموقع مملوكة وتظل
                                        كذلك
                                        لبنك
                                        حضرموت التجاري ويكون بنك حضرموت التجاري حراً في أن يستخدمها لأي غرض أية أفكار أو
                                        خبرات
                                        أو
                                        أساليب ترد في المعلومات التي يقدمها زائر لهذا الموقع لبنك حضرموت التجاري عن طريق
                                        هذا
                                        الموقع.
                                        ولا يخضع بنك حضرموت التجاري لأي التزامات بالسرية فيما يختص بالمعلومات المقدمة
                                        باستثناء
                                        ما هو
                                        متفق عليه من قبل كيان بنك حضرموت التجاري الذي له علاقة مباشرة مع العميل أو،
                                        بطريقة أخرى،
                                        كما
                                        هو متفق عليه تحديداً أو مطلوب قانوناً.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <!-- <div class="row m-0">
                    <div class="col-9">
                    </div>
                    <div class="col-3">
                        <div>
                            <div class="more" (click)="showTerms = false">
                                تم
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn more" (click)="modal.close('Save click')">تم</button>
        </div>
    </ng-template>
    <!-- <div class="temsDiv" *ngIf="showTerms == true">
        <div class="termsDivBody">
            <section class="slider terms">
                <div>
                    <div id="slid1">
                        <div>
                            <img class="leftBaner" src="../../assets/images/HBank/SVG/Untitled.svg" />
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p class="header">
                                    الشروط و الأحكام
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p class="Par">
                                    تنويه: يرجى قراءة هذه الشروط والأحكام بدقة.
                                </p>
                            </div>
                        </div>
                        <br>
                        <br>
                        <div class="row Par">
                            <div class="col-4">
                                <div class="block block-slid1">

                                </div>
                            </div>
                            <div class="col-8 Par">
                                بدخولك هذا الموقع وأي صفحات فيه، فأنت توافق على الالتزام بالشروط والأحكام أدناه. فإذا لم
                                تكن
                                موافقاً على الأحكام والشروط أدناه، فلا تدخل هذا الموقع أو أي صفحات فيه.
                                جميع الحقوق محفوظة
                                <br>
                                حقوق الطبع في الصفحات والشاشات التي تعرض الصفحات وفي المعلومات والمواد الواردة فيها وفي
                                ترتيبها، مملوكة لبنك حضرموت التجاري ما لم تتم الإشارة إلى غير ذلك.

                            </div>
                        </div>
                        <br>
                        <br>
                        <div class="row Par">
                            <div class="col-4">
                                <div class="block block-slid1">
                                    العلامات التجارية
                                </div>
                            </div>
                            <div class="col-8 Par">
                                بنك حضرموت التجاري وشعاره هي علامات تجارية وعلامات خدمية مسجلة. كما يجوز للبنك أيضاً
                                حقوق
                                العلامات التجارية والعلامات الخدمية في العلامات الأخرى الواردة في الصفحات.
                            </div>
                        </div>
                        <br>
                        <br>
                        <div class="row Par">
                            <div class="col-4">
                                <div class="block block-slid1">استخدام المعلومات والمواد</div>
                            </div>
                            <div class="col-8 Par">
                                المعلومات والمواد الواردة في هذه الصفحات، والشروط والأحكام والأوصاف التي تظهر تخضع
                                للتغيير.
                                لا تتوفر كافة المنتجات والخدمات في كافة المناطق الجغرافية. يخضع تأهلك للمنتجات وخدمات
                                بعينها
                                لما يقرره ويقبله بنك حضرموت التجاري في النهاية. المنتجات الاستثمارية ليست إلزاماً على
                                بنك
                                حضرموت التجاري وليست مضمونة من قبله.
                            </div>
                        </div>
                        <br>
                        <br>
                        <div class="row Par">
                            <div class="col-4">
                                <div class="block block-slid1">
                                    عدم الضمان
                                </div>
                            </div>
                            <div class="col-8 Par">
                                المعلومات والمواد الواردة في هذا الموقع بما في ذلك النصوص والأشكال أو الروابط أو البنود
                                الأخرى مقدمة "كما هي عليه" و "حسب توفرها" ولا يضمن بنك حضرموت التجاري دقة أو كفاية أو
                                اكتمال
                                هذه المعلومات والمواد ويتنازل صراحة عن المسؤولية عن الأخطاء أو السهو في المعلومات
                                والمواد.
                                ولا يتم إعطاء أي ضمان من أي نوع، لا ضمني ولا صريح، ولا قانوني بما في ذلك وليس حصراً على
                                ضمانات عدم التعدي على حقوق الآخرين، الملكية، القابلية للمتاجرة، الصلاحية لغرض محدد
                                والخلو من
                                فيروسات الكمبيوتر، بمعية هذه المعلومات والمواد.
                            </div>
                        </div>
                        <br>
                        <br>
                        <div class="row Par">
                            <div class="col-4">
                                <div class="block block-slid1">

                                </div>
                            </div>
                            <div class="col-8 Par"></div>
                        </div>
                        <br>
                        <br>
                        <div class="row Par">
                            <div class="col-4">
                                <div class="block block-slid1">
                                    محدودية المسؤولية
                                </div>
                            </div>
                            <div class="col-8 Par">
                                لا يكون بنك حضرموت التجاري في أي حال مسؤولاً عن أي أَضرار بما في ذلك وليس حصراً على
                                الأضرار
                                المباشرة أو غير المباشرة أو العرضية أو المترتبة، أو الخسائر أو النفقات الناشئة فيما يتصل
                                بهذا الموقع أو استخدامه أو عدم المقدرة على استخدامه من قبل أي شخص أو فيما يتصل بأي فشل
                                في
                                الأداء، أو خطأ أو سهو أو انقطاع أو خلل أو تأخير في التشغيل أو الإرسال أو فيروس كمبيوتر
                                أو
                                فشل خط أو نظام، حتى لو كان بنك حضرموت التجاري أو ممثليه قد تم إخطارهم باحتمال حدوث مثل
                                هذه
                                الأضرار أو الخسائر أو النفقات. وتتحمل أنت شخصياً مخاطر الروابط الإلكترونية لمصادر أخرى
                                على
                                الإنترنت، حيث لم يقم بنك حضرموت التجاري بالتحقق أو التأكد من أو مراقبة أو اعتماد
                                المحتويات
                                والدقة والآراء التي يتم التعبير عنها والروابط الإلكترونية الأخرى التي تقدمها تلك
                                المصادر.
                            </div>
                        </div>
                        <br>
                        <br>
                        <div class="row Par">
                            <div class="col-4">
                                <div class="block block-slid1">
                                    ما يتم تقديمه
                                </div>
                            </div>
                            <div class="col-8 Par">
                                تعتبر كافة المعلومات المقدمة لبنك حضرموت التجاري عن طريق هذا الموقع مملوكة وتظل كذلك
                                لبنك
                                حضرموت التجاري ويكون بنك حضرموت التجاري حراً في أن يستخدمها لأي غرض أية أفكار أو خبرات
                                أو
                                أساليب ترد في المعلومات التي يقدمها زائر لهذا الموقع لبنك حضرموت التجاري عن طريق هذا
                                الموقع.
                                ولا يخضع بنك حضرموت التجاري لأي التزامات بالسرية فيما يختص بالمعلومات المقدمة باستثناء
                                ما هو
                                متفق عليه من قبل كيان بنك حضرموت التجاري الذي له علاقة مباشرة مع العميل أو، بطريقة أخرى،
                                كما
                                هو متفق عليه تحديداً أو مطلوب قانوناً.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="row">
            <div class="col-9">
            </div>
            <div class="col-3">
                <div>
                    <div class="more" (click)="showTerms = false">
                        تم
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>