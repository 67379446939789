<section class="slider">
    <div>
        <div id="slid1">
            <div>
                <img class="leftBaner" src="../../assets/images/HBank/SVG/Untitled.svg" />
            </div>
            <div class="row Par">
                <div class="col-12">
                    <div class="block block-slid1">
                        <p class="header">يمكنك التواصل مع مسؤول حماية البيانات التابع للبنك عبر الوسائل التالية: </p>
                    </div>
                </div>
            </div>
            <div class="row Par Par-custom">
                <!-- <div class="title">
                    <div class="block block-slid1">
                        <p class="header">يمكنك التواصل مع مسؤول حماية البيانات التابع للبنك عبر الوسائل التالية: </p>
                    </div>
                </div> -->
                <div class="col block-pag">
                    <span class="title">
                        البريد الالكتروني:
                    </span>
                    customer.service@hadhramoutbank.com
                    <br>
                    <span class="title">رقم هاتف خدمة العملاء: </span>
                    009675311155
                    <br>
                    <span class="title">العنوان:</span>
                    اليمن – حضرموت – المكلا - الديس

                </div>
            </div>

        </div>
    </div>
</section>