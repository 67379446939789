import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { GoolsComponent } from './pages/info/gools/gools.component';
import { NewsComponent } from './pages/info/news/news.component';
import { SubComponent } from './pages/info/news/sub/sub.component';
import { RulesComponent } from './pages/info/rules/rules.component';
import { SeenComponent } from './pages/info/seen/seen.component';
import { WhoComponent } from './pages/info/who/who.component';
import { AccountComponent } from './pages/service/account/account.component';
import { FxComponent } from './pages/service/fx/fx.component';
import { TransComponent } from './pages/service/trans/trans.component';

const routes: Routes = [
  {
    path: '', component: HomeComponent
  },
  {
    path: 'who', component: WhoComponent
  },
  {
    path: 'ourRules', component: RulesComponent
  },
  {
    path: 'seen', component: SeenComponent
  },
  {
    path: 'gools', component: GoolsComponent
  },
  {
    path: 'news', component: NewsComponent
  },
  {
    path: 'news-sub/:id', component: SubComponent
  },
  {
    path: 'current-account', component: AccountComponent
  },
  {
    path: 'fx', component: FxComponent
  },
  {
    path: 'trans', component: TransComponent
  },
  {
    path: 'contact-us', component: ContactusComponent
  },
  {
    path: 'protection',
    loadChildren: () => import('./pages/privacy-and-protection/privacy-and-protection.module').then((m) => m.PrivacyAndProtectionModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
