import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { SliderComponent } from './slider/slider.component';
import { WhoComponent } from './pages/info/who/who.component';
import { RulesComponent } from './pages/info/rules/rules.component';
import { SeenComponent } from './pages/info/seen/seen.component';
import { GoolsComponent } from './pages/info/gools/gools.component';
import { AccountComponent } from './pages/service/account/account.component';
import { FxComponent } from './pages/service/fx/fx.component';
import { TransComponent } from './pages/service/trans/trans.component';
import { NewsComponent } from './pages/info/news/news.component';
import { SubComponent } from './pages/info/news/sub/sub.component';
import { PrivcyComponent } from './pages/privcy/privcy.component';
import { PolicyComponent } from './pages/privcy/policy/policy.component';
import { KnowledgComponent } from './pages/privcy/knowledg/knowledg.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { PrivacyAndProtectionComponent } from './pages/privacy-and-protection/privacy-and-protection.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    SliderComponent,
    WhoComponent,
    RulesComponent,
    SeenComponent,
    GoolsComponent,
    AccountComponent,
    FxComponent,
    TransComponent,
    NewsComponent,
    SubComponent,
    PrivcyComponent,
    PolicyComponent,
    KnowledgComponent,
    ContactusComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModalModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
