import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild('navbarToggler') navbarToggler!: ElementRef;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  navBarTogglerIsVisible() {
    return this.navbarToggler.nativeElement.offsetParent !== null;
  }
  navigate(route: string) {
    if (this.navBarTogglerIsVisible()) {
      this.navbarToggler.nativeElement.click();
    }


    this.router.navigate([route])
  }
}
