<section class="slider">
    <div>
        <div id="slid1">
            <div>
                <img class="leftBaner" src="../../assets/images/HBank/SVG/Untitled.svg" />
            </div>
            <div class="row">
                <div class="col-lg-6 col-12 mb-3">
                    <p class="header">
                        مبادئ البنك وقيمه
                    </p>
                </div>
            </div>
            <div class="row Par">
                <div class="col-4 d-block-lg">
                    <div class="block block-slid1">
                        <!-- <img src="../../assets/images/HBank/SVG/manLogo.svg" /> -->
                    </div>
                </div>
                <div class="col-lg-8 col-12 Par">
                    من حضرموت البداية التي ألهمتنا وأطلقت عنان رؤيتنا بماضيها ومورثها العريق ومن كافة مناطق اليمن
                    <span class="bold">
                        فكل
                        شيء فيها يلهمنا ويكسبنا قيم ومبادئ راسخة
                    </span>
                    <br>
                    <br>
                    <span class="bold">إتقان</span>
                    في أدق التفاصيل.
                    <span class="bold">تعلو</span>
                    فيها
                    <span class="bold">الهمم،</span>
                    وفيها
                    <span class="bold">النقاء</span>
                    ورحابة الآفاق،
                    <span class="bold">
                        صدق وأمانة، وثبات
                    </span>
                    على المبادئ،
                    <span class="bold">
                        أمناً وقوة، وتفرداً
                    </span>
                    يميزها
                    <span class="bold">
                        وأخلاقاً
                    </span>
                    تحلى بها من يسكنها.
                    <br>
                    <br>
                    إرثٌ يلهمنا أن نستثمره ونتداوله.

                </div>
            </div>

        </div>
    </div>
</section>