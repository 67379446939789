<section class="slider">
    <div>
        <div id="slid1">
            <div>
                <img class="leftBaner" src="../../assets/images/HBank/SVG/Untitled.svg" />
            </div>
            <div class="row">
                <div class="col-12 mb-3">
                    <p class="header">
                        بيع وشراء العملات
                    </p>
                </div>
            </div>
            <div class="row ">
                <div class="col-4">
                    <div class="block block-slid1">
                    </div>
                </div>
                <div class="col-7 Par">
                    شراء وبيع العملات الأجنبية الرئيسية
                </div>
            </div>

        </div>
    </div>
</section>